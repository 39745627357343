import { envs, urls } from '@sentry/shared';
import wretch from 'wretch';
import QueryStringAddon from 'wretch/addons/queryString';
const { getApiUrl, getAdobeidServicesUrl, getAuthServicesUrl } = urls.create(envs.getAppEnv);

import { getApplicationState } from '$store/application-store';
import { ENVIRONMENTS } from '@sentry/shared/dist/environments';

async function getAuthServicesEndpoint() {
  const {
    queryState: { client_id, relay },
  } = await getApplicationState();


  const apiUrl =
    envs.getAppEnv() !== ENVIRONMENTS.prod
      ? 'https://auth-stg1.services.adobe.com'
      : getAuthServicesUrl();  

  return wretch(apiUrl)
    .addon(QueryStringAddon)
    .headers({
      'x-ims-clientid': client_id,
      'X-Debug-Id': relay,
    });
}

async function getImsEndpoint(qsOverride?: {
  client_id: string;
  relay: string;
}) {
  const apiUrl = getApiUrl();

  const { queryState } = qsOverride
    ? { queryState: qsOverride }
    : await getApplicationState();

  const { client_id, relay } = queryState;

  return wretch(apiUrl).addon(QueryStringAddon).headers({
    'x-ims-clientid': client_id,
    'X-Debug-Id': relay,
  });
}

async function getAdobeidServicesEndpoint() {
  const {
    queryState: { client_id, relay },
  } = await getApplicationState();

  const apiUrl =
    envs.getAppEnv() !== ENVIRONMENTS.prod
      ? 'https://adobeid-na1-stg1.services.adobe.com'
      : getAdobeidServicesUrl();

  return wretch(apiUrl).addon(QueryStringAddon).headers({
    'x-ims-clientid': client_id,
    'X-Debug-Id': relay,
  });
}

export { getImsEndpoint, getAuthServicesEndpoint, getAdobeidServicesEndpoint };
